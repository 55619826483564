import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import Network from '../../Service/Network';
import config from '../../config';
const api = new Network();
const moment = require('moment'); // require

export default class Journal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [
				{
					category: {
						name: ''
					},
					banner_pic: {
						url: ''
					}
				}
			],
			listInSlide: [
				{
					banner_pic: {
						url: ''
					}
				}
			]
		};
		this.getData = this.getData.bind(this);
		this.getDataSlide = this.getDataSlide.bind(this);
	}
	async getData() {
		try {
			const response = await api.get('/journals?_limit=6&_sort=created_at:DESC');
			console.log(response);
			this.setState({
				data: response
			});
		} catch (err) {
			console.log('Pygmalion Error', err.response);
		}
	}
	async getDataSlide() {
		try {
			const response = await api.get('/journals?_limit=3&_sort=created_at:DESC');
			console.log(response);
			this.setState({
				listInSlide: response
			});
		} catch (err) {
			console.log('Pygmalion Error', err.response);
		}
	}
	componentDidMount() {
		this.getData();
		this.getDataSlide();
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}
	render() {
		return (
			<div style={{paddingTop: '77.08px'}}>
				<div className="slide-banner">
					<Carousel indicators={false}>
						{this.state.listInSlide.map((cont, index) => {
							return (
								<Carousel.Item key={index}>
									<img
										className="d-block w-100 img-slide-cus"
										src={`${config.domain}${cont.banner_pic.url}`}
										alt="First slide"
									/>
									<div className="overlay" />
									<Carousel.Caption>
										<div className="title-silde">{cont.title}</div>
										<p className="p-in-slide">{cont.short_des}</p>
										<NavLink
											to={`/journal-detail/${cont.id}`}
											className="wrap-continue-button con-but-cs"
										>
											<div className="continue-button hvr-sweep-to-right">CONTINUE READING</div>
										</NavLink>
									</Carousel.Caption>
								</Carousel.Item>
							);
						})}
					</Carousel>
				</div>
				<div className="background-white">
					<div className="container container-cs">
						{/* <div className="two-line" /> */}
					</div>
					<div className="container container-cs">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div className="the_journal">THE JOURNAL</div>
							</div>
						</div>
						<div className="row">
							{this.state.data.map((jou, index) => {
								return (
									<div key={index} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-article-custom">
										<div className="wrap-article">
											<div className="time-post" style={{padding: '20px'}}>
												{jou.category ? jou.category.name : 'unknown'} |{' '}
												{moment(jou.created_at).format('DD MMMM YYYY')}
											</div>
											<div className="wrap-img-article" style={{padding: '0 15px'}}>
												{
													jou.banner_pic.url ? (<img
														className="img-article"
														src={`${config.domain}${jou.banner_pic.url}`}
														alt=""
													/>) : null
												}
												
											</div>
											<div className="title-article" style={{padding: '0 20px'}}>{jou.title}</div>
											<div className="content-article" style={{padding: '0 20px 12px'}}>{jou.short_des}</div>
											<NavLink to={`/journal-detail/${jou.id}`} className="discover-more" style={{padding: '0 20px'}}>
												DISCOVER MORE
											</NavLink>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className="banner-2">
					<div className="layer" />
					<div className="banner-2-content">
						<div className="feature">FEATURED COLLECTION</div>
						<NavLink to="/feature-collection" className="wrap-continue-button">
							<div className="continue-button hvr-sweep-to-right">DISCOVER MORE</div>
						</NavLink>
					</div>
				</div>
			</div>
		);
	}
}
