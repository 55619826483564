import React, { Component } from "react";
import { NavLink } from "react-router-dom";


export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true
    };
  }

  

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    console.log("Da vao day");
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;
    // console.log("=====>", prevScrollpos);
    const currentScrollPos = window.pageYOffset;
    if(currentScrollPos>120){
      const visible = prevScrollpos > currentScrollPos;
      this.setState({
        visible,
        prevScrollpos: currentScrollPos,
      });
    }
  };

  render() {
    return (
      <header>
        <nav className={this.state.visible ? (`navbar navbar-expand-lg navbar-light nav-head nav-head-kitin`) : (`navbar navbar-expand-lg navbar-light nav-head nav-head-hidden`) }>
            <NavLink className="navbar-brand logo" to="/">
                <img src="/img/PML_LOGO-1.png" alt="" />
            </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                  <NavLink to="/" className="nav-link"> About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/time-line" className="nav-link"> THE HERITAGE</NavLink>
              </li>
              <li className="nav-item" id="timepiece-care">
                <NavLink to="/timepiececare" className="nav-link"> Timepiece care</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/feature-collection" className="nav-link"> Featured Collection</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/journal" className="nav-link"> Journal</NavLink>
              </li>
            </ul>
            <form className="form-inline my-2 my-lg-0">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <NavLink to="/contact-us" className="nav-link contact-head"> Contact Us</NavLink>
                </li>
              </ul>
            </form>
          </div>
        </nav>
      </header>
    );
  }
}
