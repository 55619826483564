import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Network from '../../Service/Network';
import config from '../../config';
const api = new Network();
const moment = require('moment'); // require

export default class JournalDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			styleButtonNext: 'next next-jou',
			styleButtonPre: 'pre pre-jou',
			idJouNext: '',
			idJouPre: '',
			data: {
				category: {
					name: ''
				},
				banner_pic: {
					url: ''
				},
				pic_2: {
					url: ''
				},
				pic_3: {
					url: ''
				},
				pic_4: {
					url: ''
				},
				first_para: '',
				second_para: '',
				third_para: ''
			},
			jouRelate: [
				{
					category: {
						name: ''
					},
					banner_pic: {
						url: ''
					},
					pic_2: {
						url: ''
					},
					pic_3: {
						url: ''
					},
					pic_4: {
						url: ''
					}
				}
			]
		};

		this.getData = this.getData.bind(this);
		this.getDataJournalRelate = this.getDataJournalRelate.bind(this);
		this.getDataNextPre = this.getDataNextPre.bind(this);
		this.splitPara = this.splitPara.bind(this);
	}
	async getData() {
		try {
			const jouId = this.props.match.params.id;
			const response = await api.get(`/journals/${jouId}`);
			if (response) {
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				await this.setState({
					data: response
				});
			}
		} catch (err) {
			console.log('Pygmalion Error', err.response);
		}
	}
	async getDataJournalRelate() {
		try {
			const jouId = this.props.match.params.id;
			const response = await api.get(`/journals?_limit=3&_sort=created_at:DESC&id_ne=${jouId}`);
			console.log(response);
			if (response) {
				await this.setState({
					jouRelate: response
				});
			}
		} catch (err) {
			console.log('Pygmalion Error', err.response);
		}
	}

	async getDataNextPre() {
		try {
			const jouId = this.props.match.params.id;
			const preResponse = await api.get(`/journals?_limit=1&_sort=id:DESC&id_lt=${jouId}`);
			const nextResponse = await api.get(`/journals?_limit=1&id_gt=${jouId}`);
			console.log(preResponse, nextResponse);
			if (preResponse.length > 0) {
				await this.setState({
					styleButtonPre: 'pre',
					idJouPre: preResponse[0].id
				});
			} else {
				await this.setState({
					styleButtonPre: 'pre pre-jou',
					idJouPre: ''
				});
			}
			if (nextResponse.length > 0) {
				await this.setState({
					styleButtonNext: 'next',
					idJouNext: nextResponse[0].id
				});
			} else {
				await this.setState({
					styleButtonNext: 'next next-jou',
					idJouNext: ''
				});
			}
		} catch (err) {
			console.log('Pygmalion Error', err.response);
		}
	}
	splitPara(para) {
		return para.split('\n');
	}
	componentDidMount() {
		this.getData();
		this.getDataJournalRelate();
		this.getDataNextPre();
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}
	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.getData();
			this.getDataJournalRelate();
			this.getDataNextPre();
		}
	}
	render() {
		const jou = this.state.data;

		return (
			<div style={{paddingTop: '77.08px'}}>
				<div className="title-wrap">
					<div className="time-category">
					{(jou.category)?(jou.category.name):null} | {moment(jou.created_at).format('DD MMMM YYYY')}
					</div>
					<div className="title-journal-detail">{jou.title}</div>
					<div className="word-and-photo">
						Words & Photos <span className="by">by</span> {jou.author}
					</div>
				</div>
				<div className="wrap-img-banner">
					{
						jou.banner_pic.url ? (<img className="img-banner-jou-detail" src={`${config.domain}${jou.banner_pic.url}`} alt="" />) : null
					}
				</div>
				<div className="index-1">
					<span className="index-jou">index 1:</span> {jou.des_banner_pic}
				</div>
				<div className="container container-cs-jou">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							{this.splitPara(jou.first_para).map((para, index) => {
								return (
									<div key={index} className="para-1-jou">
										{para}
									</div>
								);
							})}
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<div className="wrap-img-jou-1">
								{
									jou.pic_2.url ? (<img className="img-2-col" src={`${config.domain}${jou.pic_2.url}`} alt="" />) : null
								}
							</div>
							<div className="index-2">
								<span className="index-jou">INDEX 2:</span> {jou.des_pic_2}
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<div className="wrap-img-jou-1">
								{
									jou.pic_3.url ? (<img className="img-2-col" src={`${config.domain}${jou.pic_3.url}`} alt="" />) : null
								}	
							</div>
							<div className="index-2">
								<span className="index-jou">INDEX 3:</span> {jou.des_pic_3}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							{this.splitPara(jou.second_para).map((para, index) => {
								return (
									<div key={index} className="para-2">
										{para}
									</div>
								);
							})}
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div className="wrap-img-2-jou">
							{
								jou.pic_4.url ? (<img className="img-2-jou" src={`${config.domain}${jou.pic_4.url}`} alt="" />) : null
							}
								
							</div>
							<div className="index-1">
								<span className="index-jou">index 4:</span> {jou.des_pic_4}
							</div>
							{this.splitPara(jou.third_para).map((para, index) => {
								return (
									<div key={index} className="para-2">
										{para}
									</div>
								);
							})}
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div className="pre-next">
								{this.state.idJouPre != '' ? (
									<NavLink
										to={`/journal-detail/${this.state.idJouPre}`}
										className={this.state.styleButtonPre}
									>
										PREVIOUS POST
									</NavLink>
								) : (
									<div className={this.state.styleButtonPre}>PREVIOUS POST</div>
								)}
								{this.state.idJouNext != '' ? (
									<NavLink
										to={`/journal-detail/${this.state.idJouNext}`}
										className={this.state.styleButtonNext}
									>
										NEXT POST
									</NavLink>
								) : (
									<div className={this.state.styleButtonNext}>NEXT POST</div>
								)}
								
							</div>
						</div>
					</div>
				</div>
				<div className="container container-cs">
					<div className="share-this">
						SHARE THIS:
						<span className="icon-share">
							<i className="fa fa-twitter" />
						</span>
						<span className="icon-share">
							<i className="fa fa-facebook-f" />
						</span>
						<span className="icon-share">
							<i className="fa fa-pinterest-p" />
						</span>
					</div>
					<div className="the_journal_1">THE JOURNAL</div>
					<div className="row row-in-jou">
						{this.state.jouRelate.map((jou, index) => {
							return (
								<div key={index} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-article-custom">
									<div className="wrap-article">
										<div className="time-post" style={{padding: '20px'}}>
											{jou.category?jou.category.name:null} | {moment(jou.created_at).format('DD MMMM YYYY')}
										</div>
										<div className="wrap-img-article" style={{padding: '0 15px'}}>
											{
												jou.banner_pic.url ? (<img
													className="img-article"
													src={`${config.domain}${jou.banner_pic.url}`}
													alt=""
												/>) : null
											}
										</div>
										<div className="title-article" style={{padding: '0 20px'}}>{jou.title}</div>
										<div className="content-article" style={{padding: '0 20px 12px'}}>{jou.short_des}</div>
										<NavLink to={`/journal-detail/${jou.id}`} className="discover-more" style={{padding: '0 20px'}}>
											DISCOVER MORE
										</NavLink>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
