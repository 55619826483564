import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import Network from "../../Service/Network";
import config from "../../config";
import ReactMarkdown from "react-markdown";

const api = new Network();

var settings = {
  className: "owl-carousel owl-theme",
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export default class FeatureCollection extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.state = {
      data: {},
      bgImage: ''
    };
  }

  async getData() {
    try {
      const response = await api.get("/feature");
      if (response) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        this.setState({
          data: response,
          bgImage: `url(${config.domain}${response.bgImage.url})`
        });
      }
    } catch (err) {
      console.log("Pygmalion Error", err.response);
    }
  }

  componentDidMount() {
    document.title = "Featured Collections";
    this.getData();
  }

  render() {
    const dataFeature = this.state.data;

    let arraySliderOne = (
      dataFeature.Slide_one || []
    ).map((item, index) => {
      return (
        <div className="row" key={index} className="slideOne">
            <div className="col-md-6 item p-0">
              <img src={`${config.domain}${item.image_slide.url}`} alt="" />
            </div>
            <div className="col-md-6" className="content-slideOne">
              <div className="contain-content-slide">
                  <div className="title-slide-collection">
                      {item.title_slide}
                  </div>
                  <div className="content-slide-collection">
                    {/* {item.content_slide} */}
                     <ReactMarkdown source={item.content_slide} />
                   </div>
              </div>
              <div className="hr-line-kitin-one"></div>
            </div>
        </div>
      );
    });

   
    let arraySliderTwo = (
      dataFeature.Slide_two || []
    ).map((item, index) => {
      return (
        <div className="row" key={index} className="slideTwo">
            <div className="col-md-6" className="content-slideTwo">
              <div className="contain-content-slideTwo">
                  <div className="title-slide-collectionTwo">
                    {item.title_slide}
                  </div>
                  <div className="content-slide-collectionTwo">
                    {/* {item.content_slide} */}
                    <ReactMarkdown source={item.content_slide} />
                  </div>
              </div>
              <div className="hr-line-kitin-two"></div>
            </div>

            <div className="col-md-6 item p-0">
              <img src={`${config.domain}${item.image_slide.url}`} alt="" />
            </div>
        </div>
      );
    });

    return (
      <div style={{paddingTop: '77.08px'}}>
        <section className="collection-head" style={{backgroundImage: this.state.bgImage, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        </section>
        <div className="featured-content">
          <div className="container">
            <div className="contain-collection-title">
              <div className="collection-title">
                <div className="coll-title-detail">{dataFeature.title}</div>
                <div className="coll-txt-detail">{dataFeature.content}</div>
              </div>
            </div>
            <Slider {...settings}>
                  {arraySliderOne}
            </Slider>
            <div className="space-slide">
              <Slider {...settings}>
                    {arraySliderTwo}
              </Slider>
            </div>
          </div>
            
          <div className="container kitin-ft-picture">
            {dataFeature.pictureBig ? (<img src={`${config.domain}${dataFeature.pictureBig.url}`} alt="" />) : null}
          </div>
        </div>
      </div>
    );
  }
}
