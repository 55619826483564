import React, { Component } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Network from "../../Service/Network";
import config from "../../config";
import ReactMarkdown from "react-markdown";


const api = new Network();

export default class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.changeMuted = this.changeMuted.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      isMuted: true,
      data: {},
      bgAbout: "url(../img/7_text.png)",
    };
  }
  changeMuted() {
    this.setState({
      isMuted: !this.state.isMuted,
    });
  }
  async getData() {
    try {
      const response = await api.get("/about");
      this.setState({
        data: response,
        bgAbout: `url(${config.domain}${response.bgAbout.url})`,
      });
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (err) {
      console.log("Pygmalion Error", err.response);
    }
  }

  componentDidMount() {
    document.title = "Pygmalion Gallery";
    this.getData();
  }

  render() {
    const dataAbout = this.state.data;
    let title = "";
    if (dataAbout.title) {
      title = dataAbout.title.split(".").map((item, i) => {
        if (item) {
          return <div key={i}>{item}.</div>;
        }
      });
    }
    return (
      <div style={{ paddingTop: "77.08px" }}>
        <div className="slider">
          <button
            onClick={this.changeMuted}
            id="demo"
            className={this.state.isMuted ? "mute-on" : "mute-off"}
          ></button>
          <video
            id="autoplayVideo"
            playsInline
            controls
            muted={this.state.isMuted}
            autoplay="autoplay"
            loop="loop"
          >
            {this.state.data.video ? (
              <source
                src={`${config.domain}${dataAbout.video.url}`}
                type="video/mp4"
              />
            ) : null}
          </video>
        </div>
        <div
          className="about"
          style={{
            backgroundImage: this.state.bgAbout,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="about-content-kitin">
            <div className="about-content-detail">{title}</div>
          </div>
        </div>
        <section className="master container-fluid">
          <div className="row">
            <div className="col-md-5 img-left">
              {dataAbout.picture ? (
                <img src={`${config.domain}${dataAbout.picture.url}`} alt="" />
              ) : null}
            </div>
            <div className="col-md-7 master-right">
              <div className="master-title">{dataAbout.titleTwo}</div>
              {/* <div
                className="master-content"
                dangerouslySetInnerHTML={{
                  __html: dataAbout.content,
                }}
              > 
              </div>
              */}
                <ReactMarkdown className="master-content" source={dataAbout.content} />
              
              <div className="btn-master">
                <button
                  type="button"
                  className="btn btn-master-style hvr-sweep-to-right"
                >
                  <Link to="/feature-collection">EXPLORE OUR COLLECTION</Link>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
