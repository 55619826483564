import AboutUs from "./Components/AboutUs/AboutUs";
import VintageAp from "./Components/VintageAp/VintageAp";
import TimepieceCare from "./Components/TimepieceCare/TimepieceCare";
import FeatureCollection from "./Components/FeatureCollection/FeatureCollection";
import ContactUs from "./Components/ContactUs/ContactUs";
import Journal from "./Components/Journal"
import JournalDetail from "./Components/Journal-detail";

const routes = [
  {
    path: "/",
    exact: true,
    main: AboutUs
  },
  {
    path: "/time-line",
    exact: true,
    main: VintageAp
  },
  {
    path: "/timepiececare",
    exact: true,
    main: TimepieceCare
  },
  {
    path: "/feature-collection",
    exact: true,
    main: FeatureCollection
  },
  {
    path: "/contact-us",
    exact: true,
    main: ContactUs
  },
  {
    path: "/journal",
    exact: true,
    main: Journal
  },//JOURNAL
  {
    path: "/journal-detail/:id",
    exact: true,
    main: JournalDetail
  }//JOURNAL-DETAIL
];

export default routes;
