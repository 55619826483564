import React, { Component } from "react";
import Network from "../../Service/Network";
import config from "../../config";
const api = new Network();

export default class VintageAp extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.state = {
      data: {},
      bgImage: ''
    };
  }

  async getData() {
    try {
      const response = await api.get("/vintage");
      if (response) {
        this.setState({
          data: response,
          bgImage: `url(${config.domain}${response.bgImage.url})`
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    } catch (err) {
      console.log("Pygmalion Error", err.response);
    }
  }

  componentDidMount() {
    document.title = "Vintage AP";
    this.getData();
  }

  render() {
    const dataVintage = this.state.data;

    let dataEvent = (dataVintage.arrayEvent || []).map((event, index) => {
      if (index % 2 == 0) {
        return (
          <div className="row timeline-row" key={index}>
            <div className="col-md-6 timeline-left">{/* Left */}</div>
            <div className="col-md-6 timeline-right" style={{paddingBottom: '10px'}}>
              <div className="row row-right">
                <div className="col-md-8">
                  <div className="year">{event.time}</div>
                  <div className="year-text">{event.content}</div>
                </div>
                <div className="col-md-4">
                  {event.picture ? (
                    <img src={`${config.domain}${event.picture.url}`} alt="" />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="row timeline-row" key={index}>
            <div className="col-md-6 timeline-left">
              <div className="row row-right">
                <div className="col-md-4">
                  {event.picture ? (
                    <img src={`${config.domain}${event.picture.url}`} alt="" />
                  ) : null}
                </div>
                <div className="col-md-8">
                  <div className="year">{event.time}</div>
                  <div className="year-text">{event.content}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 timeline-right" style={{paddingBottom: '10px'}}>{/* Right */}</div>
          </div>
        );
      }
    });

    return (
      <div style={{paddingTop: '77.08px'}}>
        <section className="timeline-head" style={{backgroundImage: this.state.bgImage, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
          <div className="collection-head-content"></div>
        </section>
        <section className="timeline-content container-fluid">
          <div className="timeline">
            <div className="timeline-top" style={{marginBottom: '70px'}}>
              <div className="timeline-title">{dataVintage.title}</div>
              <div className="timeline-txt">{dataVintage.content}</div>
            </div>
            {dataEvent}
            <div className="timeline-bottom" />
          </div>
        </section>
      </div>
    );
  }
}
