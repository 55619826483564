import React, { Component } from "react";
import Network from "../../Service/Network";
import config from "../../config";

const api = new Network();

export default class TimepieceCare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  async getData() {
    try {
      const response = await api.get("/timepiece");
      this.setState({
        data: response,
      });
    } catch (err) {
      console.log("Pygmalion Error", err.response);
    }
  }

  componentDidMount() {
    document.title = `Timepiece Care`;
    this.getData();
  }

  render() {
    const dataTimepiece = this.state.data;
    return (
      <section
        className="timepiece-care container-fluid"
        style={{ paddingTop: "77.08px" }}
      >
        <div className="row ">
          <div className="col-md-6 timepiece-img-kitin">
            {dataTimepiece.picture ? (
              <img
                src={`${config.domain}${dataTimepiece.picture.url}`}
                alt=""
              />
            ) : null}
          </div>
          <div className="col-md-6 timepiece-content timepiece-kitin">
            <div className="time-title">{dataTimepiece.title}</div>

            <div className="topic" style={{ paddingTop: "15px" }}>
              <div className="topic-title">Coming Soon.</div>
            </div>
            {/* Actual search box */}
            {/* <div className="form-group has-search">
              <span className="form-control-feedback">
                <img src="/img/search-icon.svg" alt="" />
              </span>
              <input type="text" className="form-control" placeholder="Search Calibre" />
            </div> */}

            {/* <div className="topic">
              <div className="topic-title">
                01 / {dataTimepiece.titleOne}
              </div>
              <div className="topic-txt">
                {dataTimepiece.contentOne}
              </div>
            </div>
            
            <div className="topic">
              <div className="topic-title">02 / {dataTimepiece.titleTwo}</div>
              <div className="topic-txt">
                {dataTimepiece.contentTwo}
              </div>
            </div>

            <div className="topic">
              <div className="topic-title">
                03 / {dataTimepiece.titleThree}
              </div>
              <div className="topic-txt">
                {dataTimepiece.contentThree}
              </div>
            </div>

            <div className="topic">
              <div className="topic-title">
                04 / {dataTimepiece.titleFour}
              </div>
              <div className="topic-txt">
                {dataTimepiece.contentFour}
              </div>
            </div> */}
            <div className="topic-txt"></div>
          </div>
        </div>
      </section>
    );
  }
}
