import React, { Component } from "react";
import "./index.css";
export default class Footer extends Component {
  render() {
    return (
      <div className="footer-kitin">
        <div className="bg-footer">
          <div className="container-fluid">
            <div className="row pt-5 pb-5 footer-right">
              <div className="col-md-6">
                  <h5>
                    SIGN UP FOR OUR NEWSLETTER
                  </h5>
                  <div>
                    Sign up to receive updates on our latest additions and journal entries.
                  </div>
                  <p className="text-contact-email">Email Address</p>
                  <div className="contact-email">
                      <input type="text" placeholder="your@address.com" className="input-email-ft" />
                      <button className="bt-ft-submit">SUBMIT</button>
                  </div>
              </div>
              <div className="col-md-6 footer-right">
                <div>
                  <h5>FOLLOW US ON INSTAGRAM</h5>
                  <a>@pygmaliongallery</a>
                </div>
                <div className="contact-us">
                  <h5>CONTACT US</h5>
                  <a style={{textDecoration: 'underline'}}>intouch@pygmaliongallery.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">&copy; 2020 PYGMALION GALLERY. ALL RIGHTS RESERVED.</div>
      </div>
    );
  }
}
