import React, { Component } from "react";
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PulseSpinner } from "react-spinners-kit";
import Network from "../../Service/Network";

const api = new Network();

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      name: "",
      email: "",
      phone: "",
      message: "",
      messageOne: "",
      messageTwo: "",
      loadingBtn: false
    };
  }

  isChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  async getData() {
    try {
      const response = await api.get("/contact");
      this.setState({
        data: response,
      });
    } catch (err) {
      console.log("Pygmalion Error", err.response);
    }
  }

  componentDidMount() {
    document.title = "Contact Us";
    this.getData();
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    try{
      this.setState({
        loadingBtn: true
      })
      let dataMess = this.state.message + " " + this.state.messageOne + " " + this.state.messageTwo;
      let data = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: dataMess.trim() || "Empty"
      }
      if(data.name === "" || data.email === "" || data.phone === "" ){
        this.setState({
          loadingBtn: false
        })
        toast.error("Please fill all field!!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }else{
        const response = await api.post("/api/sendmail", data);
        if(response == "Success"){
          this.setState({
            loadingBtn: false
          })
          toast.success("Login Success!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }else{
          this.setState({
            loadingBtn: false
          })
          toast.error("Something went wrong please try again later!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }catch(err){
      console.log(err);
    }
  };

  render() {
    const dataContact = this.state.data;
    return (
      <section
        className="contact-us container-fluid"
        style={{ paddingTop: "77.08px" }}
      >
        <ToastContainer closeOnClick rtl={false} />
        <div className="row">
          <div className="col-md-6 contact-content">
            <div className="time-title">{dataContact.title}</div>
            <div className="contact-txt">{dataContact.content}</div>

            <div className="form-contact">
              <form
                className="form-inline"
                role="form"
                onSubmit={this.handleSubmit}
              >
                <div className="row">
                  <div className="form-group string optional bc_search_last_name">
                    <label
                      className="string optional control-label"
                      htmlFor="bc_search_last_name"
                    >
                      Name:{" "}
                    </label>
                    <input
                      className="string optional form-control"
                      type="text"
                      name="name"
                      id="bc_search_last_name"
                      onChange={this.isChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group string optional bc_search_first_name">
                    <label
                      className="string optional control-label"
                      htmlFor="bc_search_first_name"
                    >
                      Email Address:
                    </label>
                    <input
                      className="string optional form-control"
                      type="email"
                      name="email"
                      id="bc_search_first_name"
                      onChange={this.isChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group string optional">
                    <label
                      className="string optional control-label"
                      htmlFor="bc_search_first_name"
                    >
                      Contact number:{" "}
                    </label>
                    <input
                      className="string optional form-control"
                      type="number"
                      name="phone"
                      onChange={this.isChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group string optional ">
                    <label className="string optional control-label">
                      Message:{" "}
                    </label>
                    <input
                      className="string optional form-control"
                      type="text"
                      name="message"
                      onChange={this.isChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group string optional ">
                    <input
                      className="string optional form-control"
                      type="text"
                      name="messageOne"
                      onChange={this.isChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group string optional ">
                    <input
                      className="string optional form-control"
                      type="text"
                      name="messageTwo"
                      onChange={this.isChange}
                    />
                  </div>
                </div>
                <div className="btn-master">
                  <button
                    type="submit"
                    className="btn btn-master-style hvr-sweep-to-right kitin-button-fc"
                  >
                    <div
                    style={{
                      display: this.state.loadingBtn ? "block" : "none",
                      width: 35,
                      height: 20,
                    }}
                  >
                    <PulseSpinner color="white" size={40} />
                  </div>
                  {this.state.loadingBtn ? "" : "SEND"}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 contact-img">
            {dataContact.picture ? (
              <img src={`${config.domain}${dataContact.picture.url}`} alt="" />
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}
